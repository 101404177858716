<template>
  <div>
    <p class="text-2xl mb-6">PuntoVenta</p>

    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-text>
              <puntoVenta-nuevo
                @CargarLista="CargarLista"
                :PuntoVentaDatos="puntoVenta"
              ></puntoVenta-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">
          <puntoVenta-lista
            ref="PuntoVentaListaRef"
            @GetEditarPuntoVenta="GetEditarPuntoVenta"
          ></puntoVenta-lista>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import PuntoVentaNuevo from './components/PuntoVentaNuevo.vue'
import { ref } from '@vue/composition-api'
import PuntoVentaLista from './components/PuntoVentaLista.vue'
// demos

export default {
  components: { PuntoVentaNuevo, PuntoVentaLista },
  setup() {
    const puntoVenta = ref({
      id: -1,
      id_banco: 0,
      id_estado: null,
      codigo: '',
      serial: '',
      marca: '', 
      tipos_pago: [], 
      id_grupo_negocio:null,
    })
    const PuntoVentaListaRef = ref(null)
    const GetEditarPuntoVenta = data => {
      puntoVenta.value = {
        ...data,
      }
    }

    const CargarLista = () => {
      PuntoVentaListaRef.value.cargarPuntoVentas()
    }
    return {
      puntoVenta,
      CargarLista,
      GetEditarPuntoVenta,
      PuntoVentaListaRef,
    }
  },
}
</script>
