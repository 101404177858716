<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="6">
            <SelectGrupoNegocioValor
              :clearable="true"
              :label="'Grupo negocio'"
              :add="false"
              :dense="true"
              :outlined="true"
              :rules="reglas.requerido"
              v-model="datos.id_grupo_negocio"
            ></SelectGrupoNegocioValor>
          </v-col>
          <v-col cols="6">
            <SelectBanco
              v-model="datos.id_banco"
              :rules="reglas.requerido"
              :clearable="true"
              :label="'Banco'"
              :add="false"
              :dense="true"
              :outlined="true"
              :padres="{ id_tipo: 1 }"
            ></SelectBanco>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :rules="reglas.requerido"
              v-model="datos.codigo"
              :label="'Codigo'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="datos.serial"
              :label="'Serial'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="datos.marca"
              :label="'Marca'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col>
                <p>Tipos pago</p>
              </v-col>
              <v-col class="text-right">
                <v-btn icon small @click="agegarTipoPago()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="mb-4"></v-divider>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Id</th>
                    <th class="text-left">Moneda</th>
                    <th class="text-left">Tipo Pago</th>
                    <th class="text-left">Cuenta</th>
                    <th class="text-left">Comision Bancaria %</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in datos.tipos_pago" :key="index">
                    <td>
                      <span v-if="item.id > 0">{{ item.id }}</span> <span v-else>Nuevo</span>
                    </td>
                    <td>
                      <select-moneda
                        class="my-2"
                        :rules="reglas.requerido"
                        v-model="item.moneda"
                        :dense="true"
                        :outlined="true"
                      ></select-moneda>
                    </td>
                    <td>
                      <v-select
                        :rules="reglas.requerido"
                        hide-details="auto"
                        dense
                        outlined
                        item-text="nombre"
                        item-value="id"
                        :items="item.moneda.tipo_pago"
                        label="Tipo Pago"
                        v-model="item.id_tipo_pago"
                      ></v-select>
                    </td>
                    <td>
                      <SelectCuentaBancaria
                        :label="'Cuenta'"
                        :padres="{
                          id_moneda: item.moneda.id,
                          id_banco: datos.id_banco,
                          id_grupo_negocio: datos.id_grupo_negocio,
                        }"
                        :rules="reglas.requerido"
                        v-model="item.id_cuenta_bancaria"
                        :dense="true"
                        :outlined="true"
                        :add="false"
                      ></SelectCuentaBancaria>
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.comision_bancaria"
                        :label="'comision'"
                        required
                        outlined
                        dense
                        hide-details="auto"
                        type="number"
                        :rules="reglas.requerido"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarPuntoVenta()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch, mdiPlus } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
import SelectBanco from '../../banco/components/Select.vue'
import SelectTipoCuenta from '../../tipo-cuenta/components/Select.vue'
import DatePicher from '@/components/DatePicker.vue'
import SelectMoneda from '@/components/SelectMonedaJson.vue'
import SelectCuentaBancaria from '../../cuenta-bancaria/components/Select.vue'
import SelectGrupoNegocioValor from '@/components/SelectGrupoNegocioValor.vue'
export default {
  components: {
    CropImagen,
    SelectBanco,
    DatePicher,
    SelectTipoCuenta,
    SelectMoneda,
    SelectCuentaBancaria,
    SelectGrupoNegocioValor,
  },
  props: {
    PuntoVentaDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.PuntoVentaDatos)))
    const puestos = ref([])
    const form = ref()
    const cargando = ref(false)
    watch(props, () => {
      resetear()
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.PuntoVentaDatos))

      form.value.resetValidation()
    }
    onBeforeMount(() => {})

    const reglas = {
      id: -1,

      requerido: [v => !!v || 'Es requerido'],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const tipo_pago_int = {
      id: -1,
      moneda: {},
      id_cuenta_bancaria: null,
      id_tipo_pago: null,
      comision_bancaria: '',
      id_grupo_negocio: null,
    }
    const limpiar = () => {
      datos.value = {
        id: -1,
        id_banco: 0,
        id_estado: null,
        codigo: '',
        serial: '',
        marca: '',
        tipos_pago: [],
      }
      form.value.resetValidation()
    }

    let contador = -10000
    const idDefaultContador = () => {
      contador = contador + 1
      return contador
    }

    const agegarTipoPago = () => {
      datos.value.tipos_pago.push({ ...JSON.parse(JSON.stringify(tipo_pago_int)), id: idDefaultContador() })
    }

    const GuardarPuntoVenta = async () => {
      try {
        if (validarForm()) {
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          CuentasService.nuevoPuntoVenta(parametros)
            .then(response => {
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'GuardarPuntoVenta',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          
          error: error,
            funcion: 'GuardarPuntoVenta',
        })
        cargando.value = false
      }
    }

    return {
      icons: {
        mdiCloudSearch,
        mdiPlus,
      },
      valid,
      datos,
      reglas,
      GuardarPuntoVenta,
      form,
      limpiar,
      config,
      resetear,
      cargando,
      agegarTipoPago,
    }
  },
}
</script>